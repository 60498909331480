import React, { useEffect, useState } from 'react';
import { Admin, Resource } from 'react-admin';
import { useGlobal } from './context/global';
import Dashboard from './layout/Dashboard';
import Layout from './layout/Layout';
import authClient from './providers/authClient';
import restClient from './providers/restClient';
import { BlankTypeCreate, BlankTypeEdit } from './resources/blank_type';
import { BlankTypeListing } from './resources/blank_type/BlankTypeListing';
import { BlankCreate, BlankEdit, BlankList } from './resources/blanks';
import { BlankJobEdit, BlanksJobList } from './resources/blanks_job';
import {
  BLBICreate,
  BLBIEdit,
  BLBIListing,
} from './resources/blanks_listing_by_item';
import { BLIWCListing } from './resources/blanks_listing_item_with_cost';
import { BoxCreate, BoxEdit, BoxListing } from './resources/box';
import { ColorCreate, ColorEdit, ColorList } from './resources/color';
import { CostCalCulatorCreate } from './resources/cost_calculator';
import {
  FinalCalculationCreate,
  FinalCalculationEdit,
  FinalCalculationList,
} from './resources/final_calculation';
import { GlobalVariableEdit } from './resources/global_variable';
import { GlobalVariableCreate } from './resources/global_variable/GlobalVariableCreate';
import { GlobalVariableListing } from './resources/global_variable/GlobalVariableListing';
import { InkCreate, InkEdit, InkListing } from './resources/inks';
import { ItemCreate, ItemEdit, ItemList } from './resources/item';
import { ItemJobEdit, ItemJobsList } from './resources/item_job';
import {
  ItemTypeCreate,
  ItemTypeEdit,
  ItemTypeListing,
} from './resources/item_type';
import { JobCreate, JobEdit, JobList } from './resources/job';
import {
  LocationCreate,
  LocationEdit,
  LocationList,
} from './resources/locations';
import {
  RawMaterialCreate,
  RawMaterialEdit,
  RawMaterialListing,
} from './resources/raw_material';
import {
  RawMaterialTypeCreate,
  RawMaterialTypeEdit,
  RawMaterialTypeList,
} from './resources/raw_material_type';
import { ScreenCreate, ScreenEdit, ScreenListing } from './resources/screen';
import Spreadsheet from './resources/spreadsheet';
import {
  UnitsOfMeasureCreate,
  UnitsOfMeasureEdit,
  UnitsOfMeasureList,
} from './resources/unitsofmeasure';
import { UserCreate, UserEdit, UserListing } from './resources/user';
import { VendorCreate, VendorEdit, VendorList } from './resources/vendor';
import { SERVER_URL } from './config';
import axios from 'axios';

const App = () => {
  const [lastDatabase, setlastDatabase] = useState();

  const fetchDatabaseLastYear = async () => {
    const response = [{"id":1,"year":"2019","created_at":"2024-03-12T20:52:47.013Z","updated_at":"2024-03-12T20:52:47.013Z"},{"id":2,"year":"2020","created_at":"2024-03-12T20:52:47.013Z","updated_at":"2024-03-12T20:52:47.013Z"},{"id":3,"year":"2021","created_at":"2024-03-12T20:52:47.013Z","updated_at":"2024-03-12T20:52:47.013Z"},{"id":4,"year":"2022","created_at":"2024-03-12T20:52:47.013Z","updated_at":"2024-03-12T20:52:47.013Z"},{"id":5,"year":"2023","created_at":"2024-03-12T20:52:47.013Z","updated_at":"2024-03-12T20:52:47.013Z"},{"id":6,"year":"2024","created_at":"2024-03-12T20:52:47.013Z","updated_at":"2024-03-12T20:52:47.013Z"},{"id":8,"year":"2025","created_at":"2024-09-06T13:24:38.119Z","updated_at":"2024-09-06T13:24:38.119Z"}];
    setlastDatabase(response[response.length - 1].year);
    /*try {
      const response = await axios.get(`${SERVER_URL}/databases`);
      if (response.status == 200) {
        setlastDatabase(response.data[response.data.length - 1].year);
      }
    } catch (error) {
      console.log('[ERR]', error);
    }*/
  };

  const { databaseGlobal, setDatabaseGlobal, setLocationGlobal } = useGlobal();

  useEffect(() => {
    fetchDatabaseLastYear();

    const database = localStorage.getItem('db') || lastDatabase;
    const location = localStorage.getItem('location') || 'USA';
    setDatabaseGlobal(database);
    setLocationGlobal(location);
  }, []);

  useEffect(() => {
    const database = localStorage.getItem('db') || lastDatabase;
    setDatabaseGlobal(database);
    if (database) {
      localStorage.setItem('db', database);
    }
  }, [lastDatabase]);

  return (
    <Admin
      title="Aakron Costing Module"
      authProvider={authClient}
      dataProvider={restClient}
      layout={Layout}
      dashboard={Dashboard}
    >
      <Resource name="spreadsheet" list={Spreadsheet} />

      <Resource
        name="locations"
        list={LocationList}
        edit={LocationEdit}
        create={LocationCreate}
      />

      <Resource
        name="job_listings"
        list={JobList}
        edit={JobEdit}
        create={JobCreate}
      />
      <Resource
        name="screens"
        list={ScreenListing}
        edit={ScreenEdit}
        create={ScreenCreate}
      />

      <Resource
        name="blanks"
        list={BlankList}
        edit={BlankEdit}
        create={BlankCreate}
      />
      <Resource name="blank_jobs" list={BlanksJobList} edit={BlankJobEdit} />
      <Resource
        name="blank_types"
        list={BlankTypeListing}
        edit={BlankTypeEdit}
        create={BlankTypeCreate}
      />

      <Resource
        name="items"
        list={ItemList}
        edit={ItemEdit}
        create={ItemCreate}
      />
      <Resource name="item_jobs" list={ItemJobsList} edit={ItemJobEdit} />
      <Resource
        name="boxes"
        list={BoxListing}
        edit={BoxEdit}
        create={BoxCreate}
      />
      <Resource
        name="item_types"
        list={ItemTypeListing}
        edit={ItemTypeEdit}
        create={ItemTypeCreate}
      />
      <Resource
        name="inks"
        list={InkListing}
        edit={InkEdit}
        create={InkCreate}
      />

      <Resource name="blanks_listing_item_with_costs" list={BLIWCListing} />
      <Resource
        name="blanks_listing_by_items"
        list={BLBIListing}
        create={BLBICreate}
        edit={BLBIEdit}
      />

      <Resource
        name="raw_materials"
        list={RawMaterialListing}
        create={RawMaterialCreate}
        edit={RawMaterialEdit}
      />
      <Resource
        name="colors"
        list={ColorList}
        edit={ColorEdit}
        create={ColorCreate}
      />
      <Resource
        name="units_of_measures"
        list={UnitsOfMeasureList}
        edit={UnitsOfMeasureEdit}
        create={UnitsOfMeasureCreate}
      />
      <Resource
        name="rawmaterialtypes"
        list={RawMaterialTypeList}
        edit={RawMaterialTypeEdit}
        create={RawMaterialTypeCreate}
      />
      <Resource
        name="vendors"
        list={VendorList}
        create={VendorCreate}
        edit={VendorEdit}
      />

      <Resource
        name="final_calculations"
        list={FinalCalculationList}
        create={FinalCalculationCreate}
        edit={FinalCalculationEdit}
      />
      <Resource name="cost_calculator" create={CostCalCulatorCreate} />

      <Resource
        name="users"
        list={UserListing}
        edit={UserEdit}
        create={UserCreate}
      />
      <Resource
        name="app_constants"
        list={GlobalVariableListing}
        edit={GlobalVariableEdit}
        create={GlobalVariableCreate}
      />
    </Admin>
  );
};

export default App;
