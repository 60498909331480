import React, { createContext, useContext, useState } from 'react';

const GlobalContext = createContext(null);

export const useGlobal = () => {
  const context = useContext(GlobalContext);
  if (!context)
    throw new Error('useGlobal must be used within a GlobalProvider');
  return context;
};

const GlobalProvider = ({ children }) => {
  const [databaseGlobal, setDatabaseGlobal] = useState(null);
  const [locationGlobal, setLocationGlobal] = useState(null);

  const values = {
    databaseGlobal,
    setDatabaseGlobal,
    locationGlobal,
    setLocationGlobal,
  };

  return (
    <GlobalContext.Provider value={values}>{children}</GlobalContext.Provider>
  );
};

export default GlobalProvider;
