import React from 'react'
import { DeleteButton, Edit, ListButton, NumberInput, ReferenceInput, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, TopToolbar, required } from 'react-admin'
import { isModificationPermission } from '../../helpers/functions'
import BlankCostView from './BlankCostView'

const Actions = () => (
  <TopToolbar>
      <ListButton />
  </TopToolbar>
)

const ToolbarForm = (props) => {
  if(!isModificationPermission()) return false

  return (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteButton mutationMode="pessimistic" />
    </Toolbar>
  )
}

export const BlankEdit = (props) => (
  <Edit
    actions={<Actions />}
    {...props}
  >
    <SimpleForm
      toolbar={<ToolbarForm />}
    >
      <TextInput fullWidth disabled source='id' validate={required()}/>
      <NumberInput fullWidth source='blank_number' validate={required()}/>
      <TextInput fullWidth multiline source='description' validate={required()}/>
      <ReferenceInput
        label='Blank Type'
        reference='blank_types'
        source='blank_type_id'
        perPage={0}
      >
        <SelectInput fullWidth optionText='description' validate={required()} />
      </ReferenceInput>
      <NumberInput fullWidth source='cost' label='Cost($)' validate={required()}/>
      <BlankCostView type='price' />
      <BlankCostView type='inventory' />
    </SimpleForm>
  </Edit>
)
