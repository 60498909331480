import ContentCreate from '@mui/icons-material/Create'
import {
    Autocomplete,
    Box,
    Button,
    Card,
    Checkbox,
    IconButton,
    Modal,
    Table,
    TableBody,
    TableRow,
    TextField,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNotify, useRefresh } from 'react-admin'
import { isModificationPermission } from '../helpers/functions'
import { stringHelpers } from '../helpers/stringHelpers'
import restClient from '../providers/restClient'

const ListingItemCost = ({ blanksInitial, resource, docNumber }) => {
  const refresh = useRefresh()
  const notify = useNotify()
  const [blanks, setBlanks] = useState([])
  const [blanksListing, setBlanksListing] = useState([])
  const [blankEdit, setBlankEdit] = useState({})
  const [openModal, setOpenModal] = useState(false)

  const fetchBlanks = () =>
    restClient.getList('blank-list-only', {
      pagination: { page: 1, perPage: -1 },
      sort: { field: 'id', order: 'ASC' },
    })

  const editBlank = (blank) => {
    setOpenModal(true)
    setBlankEdit(blank)
  }

  const handleEditBlank = async() => {
    if(resource === 'blanks_listing_item_with_costs'){
      const response = await restClient.update('blanks_listing_item_with_costs', {
        id: docNumber,
        data: {
          blank_number: blankEdit.blank_number,
          mult: blankEdit.mult,
          div: blankEdit.div,
        },
      })

      if(response){
        notify('Blank updated successfully')
      }else{
        notify('Error updating blank')
      }
  
      refresh()
    }

    if(resource === 'blanks_listing_by_items'){
      const response = await restClient.update('blanks_listing_by_items', {
        id: docNumber,
        data: {
          blank_number: blankEdit.blank_number,
          mult: blankEdit.mult,
          div: blankEdit.div,
        },
      })

      if(response){
        notify('Blank updated successfully')
      }else{
        notify('Error updating blank')
      }
  
      refresh()
    }

    setOpenModal(false)
  }

  const selectBlankNumberEdit = (event, data) => {
    if(!data){
      return
    }
  
    const blank_number = stringHelpers.extractLeadingNumber(data)

    setBlankEdit({
      ...blankEdit,
      blank_number: Number(blank_number),
    })
  }

  const removeBlank = (blank) => {
    const currentItemBlanks = blanks.map((b) => {
      if(b.blank_number === blank.blank_number){
        const deleted = b.deleted ? false : true

        return {
          ...b,
          deleted,
        }
      }else{
        return b
      }
    })
  
    setBlanks(currentItemBlanks)
  }

  const handleRemoveBlank = async() => {
    if(resource === 'blanks_listing_item_with_costs'){
      const blanksDeleted = blanks.filter((b) => b.deleted)
      if(blanksDeleted.length === 0) return

      const transformedBlanks = blanksDeleted.map((j) => {
        return {
          deleted: j.deleted,
          blank_number: j.blank_number,
        }
      })
      
      const response = await restClient.delete('blanks_listing_item_with_costs', {
        id: docNumber,
        data: {
          blanks: transformedBlanks,
        },
      })

      if(response){
        notify('Blank(s) removed successfully')
      }else{
        notify('Error removing blank(s)')
      }

      refresh()
    }

    if(resource === 'blanks_listing_by_items'){
      const blanksDeleted = blanks.filter((b) => b.deleted)
      if(blanksDeleted.length === 0) return

      const transformedBlanks = blanksDeleted.map((j) => {
        return {
          deleted: j.deleted,
          blank_number: j.blank_number,
        }
      })
      
      const response = await restClient.delete('blanks_listing_by_items', {
        id: docNumber,
        data: {
          blanks: transformedBlanks,
        },
      })

      if(response){
        notify('Blank(s) removed successfully')
      }else{
        notify('Error removing blank(s)')
      }

      refresh()
    }
  }

  const BlankField = ({blank, index}) => {
    return (
      <TableRow key={index} style={{ borderTop: '1px solid #cdcdcd' }}>
        <th style={{ fontWeight: 400, height: '40px' }}>{blank.blank_number}</th>
        <th style={{ fontWeight: 400, textAlign: 'left' }}>{blank.blank_description || '-'}</th>
        <th style={{ fontWeight: 400 }}>{blank.mult}</th>
        <th style={{ fontWeight: 400 }}>{blank.div}</th>
        {isModificationPermission() &&
          <th>
            <IconButton
              onClick={() => editBlank(blank)}
            >
              <ContentCreate />
            </IconButton>
            <Checkbox
              checked={blank.deleted}
              onChange={() => removeBlank(blank)}
            />
          </th>
        }
      </TableRow>
    )
  }

  useEffect(() => {
    fetchBlanks().then(({ data }) => {
      const blanks = data.map(
        (b) => `${b.blank_number} - ${b.description}`
      )
      setBlanksListing(blanks)
    }).catch((err) => {
      console.log('Error fetching items', err)
    })
  }, [])

  useEffect(() => {
    setBlanks(blanksInitial)
  }, [blanksInitial])

  if(blanks === undefined) return <div>Loading...</div>

  return (
    <>
      {blanks?.length > 0
        ? <div style={{ width: '100%'}}>
            <h2>Blanks By Item</h2>

            <Table style={{ marginTop: '1rem', fontSize: '0.9rem' }}>
              <thead style={{ color: '#b7b7b7' }}>
                <TableRow>
                  <th>Blank#</th>
                  <th>Description</th>
                  <th>Multiplication</th>
                  <th>Division</th>
                  <th></th>
                </TableRow>
              </thead>

              <TableBody>
                {blanks?.map((blank, index) => <BlankField key={index} blank={blank} index={index} />)}
              </TableBody>
            </Table>

            {isModificationPermission() && blanks.filter((b) => b.deleted).length > 0 &&
              <Button
                color='error'
                variant='contained'
                onClick={handleRemoveBlank}
              >
                Delete selected records
              </Button>
            }

            <Modal
              open={openModal}
              onClose={() => setOpenModal(false)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Card style={{ padding: '1rem', width: '100%', maxWidth: '500px' }}>
                <h2 style={{ borderBottom: '1px solid #cdcdcd', padding: '0.5rem'}}>
                  Edit Blank
                </h2>

                <Autocomplete
                  options={blanksListing}
                  value={blankEdit?.blank_number + ' - ' + blankEdit?.blank_description}
                  onChange={selectBlankNumberEdit}
                  renderInput={(params) =>
                    <TextField {...params} label='Type the blank number' />
                  }
                />

                <TextField
                  fullWidth
                  label='Multiplication'
                  name='multiplication'
                  type='number'
                  onChange={(e) => setBlankEdit({ ...blankEdit, mult: e.target.value })}
                  value={blankEdit.mult}
                />

                <TextField
                  fullWidth
                  label='Division'
                  name='division'
                  type='number'
                  onChange={(e) => setBlankEdit({ ...blankEdit, div: e.target.value })}
                  value={blankEdit.div}
                />
        
                <Box>
                  <Button
                    onClick={() => setOpenModal(false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    onClick={handleEditBlank}
                  >
                    Update
                  </Button>
                </Box>
              </Card>
            </Modal>
          </div>
        :
          <div>
            No blank(s)
          </div>
      }
    </>
  )
}

export default ListingItemCost