import EditIcon from '@mui/icons-material/Edit'
import ShowIcon from '@mui/icons-material/Visibility'
import { Card } from '@mui/material'
import React from 'react'
import { CreateButton, Datagrid, EditButton, List, TextField, TopToolbar } from 'react-admin'
import { isModificationPermission } from '../../helpers/functions'
import { BLBIFilter } from './BLBIFilter'

const Actions = ({
  filters,
  displayedFilters,
  filterValues,
  resource,
  showFilter,
}) => (
  <TopToolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}

      {isModificationPermission() && <CreateButton />}
  </TopToolbar>
)

export const BLBIListing = (props) => (
  <Card className='card-wrapper'>
    <List
      title='Blanks Listing By Item'
      actions={<Actions />}
      sort={{ field: 'id', order: 'ASC' }}
      filters={<BLBIFilter />}
      perPage={10}
      {...props}
    >
      <Datagrid
        isRowSelectable={() => false}
      >
        <TextField source='id' />
        <TextField source='item_number' />
        <TextField source='blank_number' />
        <TextField source='mult' label='Multiplication' />
        <TextField source='div' label='Division' />

        <EditButton
          label={isModificationPermission() ? 'Edit' : 'View'}
          icon={isModificationPermission() ? <EditIcon /> : <ShowIcon />}
        />
      </Datagrid>
    </List>
  </Card>
)
