import { stringify } from 'querystring-es3';
import { setAuthorizationToken } from '../actions/authActions';
import { SERVER_URL } from '../config';
import axios from 'axios';

export default {
  getCustom: (resource, params) => {
    const url = `${SERVER_URL}/${resource}?${stringify(params)}`;
    return setAuthorizationToken(url)
      .then(({ json }) => ({
        data: json,
      }))
      .catch((error) => console.log(error));
  },

  getYears: async () => {
    /*const response = await axios.get(`${SERVER_URL}/databases`);
      return response;
    */
    let response = {};
    response.data = [{"id":1,"year":"2019","created_at":"2024-03-12T20:52:47.013Z","updated_at":"2024-03-12T20:52:47.013Z"},{"id":2,"year":"2020","created_at":"2024-03-12T20:52:47.013Z","updated_at":"2024-03-12T20:52:47.013Z"},{"id":3,"year":"2021","created_at":"2024-03-12T20:52:47.013Z","updated_at":"2024-03-12T20:52:47.013Z"},{"id":4,"year":"2022","created_at":"2024-03-12T20:52:47.013Z","updated_at":"2024-03-12T20:52:47.013Z"},{"id":5,"year":"2023","created_at":"2024-03-12T20:52:47.013Z","updated_at":"2024-03-12T20:52:47.013Z"},{"id":6,"year":"2024","created_at":"2024-03-12T20:52:47.013Z","updated_at":"2024-03-12T20:52:47.013Z"},{"id":8,"year":"2025","created_at":"2024-09-06T13:24:38.119Z","updated_at":"2024-09-06T13:24:38.119Z"}];
    response.status = 200;
    //console.log(response);
    return response;
  },

  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const query = {
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
      ...params.filter,
    };
    const url = `${SERVER_URL}/${resource}?${stringify(query)}`;

    return setAuthorizationToken(url)
      .then(({ headers, json }) => ({
        data: json,
        total: parseInt(headers.get('x-total-count') || 0),
      }))
      .catch((error) => console.log(error));
  },

  getOne: (resource, params) => {
    return setAuthorizationToken(`${SERVER_URL}/${resource}/${params.id}`)
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },
  getMany: (resource, params) => {
    const query = {
      id: params.ids,
    };
    const url = `${SERVER_URL}/${resource}?${stringify(query)}`;
    return setAuthorizationToken(url)
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      _sort: field,
      _order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage - 1,
      ...params.filter,
      [params.target]: params.id,
    };

    const url = `${SERVER_URL}/${resource}?${stringify(query)}`;

    return setAuthorizationToken(url)
      .then(({ headers, json }) => ({
        data: json,
        total: parseInt(headers.get('x-total-count') || 0),
      }))
      .catch((error) => console.log(error));
  },

  update: (resource, params) => {
    return setAuthorizationToken(`${SERVER_URL}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  updateMany: (resource, params) => {
    const query = {
      id: params.ids,
    };
    return setAuthorizationToken(
      `${SERVER_URL}/${resource}?${stringify(query)}`,
      {
        method: 'PUT',
        body: JSON.stringify(params.data),
      }
    )
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  create: (resource, params) =>
    setAuthorizationToken(`${SERVER_URL}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    })
      .then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
      .catch((error) => console.log(error)),

  delete: (resource, params) =>
    setAuthorizationToken(`${SERVER_URL}/${resource}/${params.id}`, {
      method: 'DELETE',
      ...(params.data ? { body: JSON.stringify(params.data) } : {}),
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error)),

  deleteMany: (resource, params) => {
    const query = {
      id: params.ids,
    };
    return setAuthorizationToken(
      `${SERVER_URL}/${resource}?${stringify(query)}`,
      {
        method: 'DELETE',
      }
    ).catch((error) => console.log(error));
  },
};
