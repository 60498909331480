import EditIcon from '@mui/icons-material/Edit'
import ShowIcon from '@mui/icons-material/Visibility'
import { Card } from '@mui/material'
import React from 'react'
import { CreateButton, Datagrid, EditButton, Filter, FunctionField, List, NumberInput, TextField, TextInput, TopToolbar } from 'react-admin'
import { isModificationPermission, roundNumber } from '../../helpers/functions'
import ColorExportModal from './ColorExportModal'

const Actions = ({
  filters,
  displayedFilters,
  filterValues,
  resource,
  showFilter,
}) => (
  <TopToolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}

    {isModificationPermission() && <CreateButton />}

    <ColorExportModal />
  </TopToolbar>
)

const FilterSearch = (props) => (
  <Filter {...props}>
    <TextInput label='Search by color code' source='code' alwaysOn />
    <TextInput label='Search by color name' source='name' alwaysOn />
    <NumberInput label='Search by color cost' source='cost_of_color' alwaysOn />
  </Filter>
)

export const ColorList = (props) => (
  <Card className='card-wrapper'>
    <List
      title='All Colors'
      actions={<Actions />}
      sort={{ field: 'id', order: 'ASC' }}
      filters={<FilterSearch />}
      {...props}
    >
      <Datagrid
        isRowSelectable={() => false}
      >
        <TextField source='id' />
        <TextField source='code' />
        <TextField source='name' />
        <FunctionField
          source='cost_of_color'
          label='Cost($)'
          render={
            record => {
              return <span>${roundNumber(record.cost_of_color)}</span>
          }}
        />
  
        <EditButton
          label={isModificationPermission() ? 'Edit' : 'View'}
          icon={isModificationPermission() ? <EditIcon /> : <ShowIcon />}
        />
      </Datagrid>
    </List>
  </Card>
)
