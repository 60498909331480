import {
  Autocomplete,
  Box,
  Button,
  Divider,
  TextField,
} from '@mui/material'
import { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import { useNotify, useRefresh } from 'react-admin'
import { stringHelpers } from '../helpers/stringHelpers'
import restClient from '../providers/restClient'

const CopyBlankForm = ({ data, docNumber, callback }) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const [items, setItems] = useState([])
  const [blanksCopy, setCopyBlanks] = useState([])
  const [selectOption, setSelectOption] = useState(null)

  const fetchItems = () => {
    return restClient.getList('item-list-only', {
      pagination: { page: 1, perPage: -1 },
      sort: { field: 'id', order: 'ASC' },
    })
  }

  const submitForm = async(e) => {
    e.preventDefault()

    if(!selectOption){
      notify('Please select item number')
    }
  
    const id = stringHelpers.extractLeadingNumber(selectOption)

    const response = await restClient.update('update-item-blanks-only', {
      id: id,
      data: {
        blanks: blanksCopy,
      },
    })

    if(response){
      notify('Blank(s) copied successfully')
      callback()
      refresh()
    }else{
      notify('Blank(s) failed to copy')
    }
  }

  const blankField = (blank) => {
    return (
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gap: '1rem',
        }}
      >
        <TextField
          label='Blank Number'
          value={blank.blank_number}
          disabled={true}
        />

        <TextField
          label='Mult'
          value={blank.mult}
          disabled={true}
        />

        <TextField
          label='Div'
          value={blank.div}
          disabled={true}
        />
      </div>
    )
  }

  useEffect(() => {
    fetchItems().then(({ data }) => {
      setItems(data)
    }).catch((err) => {
      console.log('Error fetching items', err)
    })
  }, [])


  useLayoutEffect(() => {
    const formated = data.blanks_listing_by_item.map((blank) => ({
      item_number: data.item_number,
      blank_number: blank.blank_number,
      mult: blank.mult,
      div: blank.div,
    }))

    setCopyBlanks(formated)
  }, [])

  return (
    <form
      onSubmit={submitForm}
    >
      <Box sx={{
        padding: '1rem',
      }}>
        <Autocomplete
          options={
            items.filter((i) => i.item_number != docNumber)
              .sort((a, b) => a.item_number - b.item_number)
              .map((item) => `${item.item_number} - ${item.description}`)
          }
          onChange={(e, data) => setSelectOption(data)}
          value={selectOption}
          renderInput={(params) =>
            <TextField {...params} label={`Type the item number`} />
          }
        />

        {blanksCopy.map((blank, index) => (
          <Fragment key={index}>
            {blankField(blank)}
          </Fragment>
        ))}
      </Box>

      <Divider />

      <Box sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '1rem',
      }}>
        <Button
          onClick={callback}
        >
          Cancel
        </Button>
        {selectOption &&
          <Button
            type='submit'
          >
            Add Over
          </Button>
        }
      </Box>
    </form>
  )
}

export default CopyBlankForm
