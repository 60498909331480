import { AppBar as ComponentAppBar, TitlePortal, UserMenu } from 'react-admin';
import { useGlobal } from '../context/global';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import * as React from 'react';
// It's important to pass the ref to allow Material UI to manage the keyboard navigation

const AppBar = () => {
  const { databaseGlobal, locationGlobal } = useGlobal();
  const isMinorTo2024 = () => {
    if (databaseGlobal < 2024) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <ComponentAppBar color="primary">
      <TitlePortal />

      <p>Database: {databaseGlobal}</p>

      {!isMinorTo2024() ? (
        <p style={{ marginLeft: '12px' }}>, Location: {locationGlobal}</p>
      ) : null}
    </ComponentAppBar>
  );
};

export default AppBar;
