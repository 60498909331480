import EditIcon from '@mui/icons-material/Edit';
import ShowIcon from '@mui/icons-material/Visibility';
import { Card } from '@mui/material';
import axios from 'axios';
import { SERVER_URL } from '../../config';

import React from 'react';
import {
  CreateButton,
  Datagrid,
  EditButton,
  List,
  TextField,
  TopToolbar,
  Button,
  useNotify,
} from 'react-admin';
import { isModificationPermission } from '../../helpers/functions';
import { GlobalVariableFilter } from './GlobalVariableFilter';

const Actions = ({
  filters,
  displayedFilters,
  filterValues,
  resource,
  showFilter,
}) => {
  const notify = useNotify();
  const handleCustomAction = () => {
    try {
      console.log('press');
      axios
        .post(`${SERVER_URL}/database-backup`, {})
        .then((response) => {
          notify(response.data.message);
          console.log(response.data);
        })
        .catch((err) => {
          console.log(err.response.data);
          notify(err.response.data.message);
        });
    } catch (error) {
      notify(err.response.data.message);
    }
  };

  return (
    <TopToolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {isModificationPermission() && (
        <Button label="Set Backup" onClick={() => handleCustomAction()} />
      )}
      {isModificationPermission() && <CreateButton />}
    </TopToolbar>
  );
};

export const GlobalVariableListing = (props) => (
  <Card className="card-wrapper">
    <List
      title="All Global Variable"
      {...props}
      actions={<Actions />}
      filters={<GlobalVariableFilter />}
      exporter={false}
    >
      <Datagrid isRowSelectable={() => false}>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="value" />

        <EditButton
          label={isModificationPermission() ? 'Edit' : 'View'}
          icon={isModificationPermission() ? <EditIcon /> : <ShowIcon />}
        />
      </Datagrid>
    </List>
  </Card>
);
