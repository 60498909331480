import React from 'react'
import { DeleteButton, Edit, ListButton, SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, TopToolbar, required } from 'react-admin'

const Actions = () => (
  <TopToolbar>
      <ListButton />
  </TopToolbar>
)

const ToolbarForm = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
      <DeleteButton mutationMode="pessimistic" />
    </Toolbar>
  )
}

export const UserEdit = (props) => (
  <Edit
    actions={<Actions />}
    {...props}
  >
    <SimpleForm
      toolbar={<ToolbarForm />}
    >
      <TextInput fullWidth disabled source='id' validate={required()}/>
      <TextInput fullWidth source='username' validate={required()}/>
      <SelectInput fullWidth source='role' validate={required()} choices={[
          { id: 'admin', name: 'Admin' },
          { id: 'user', name: 'User' },
      ]} />
      <TextInput fullWidth source='password' type='password' />
    </SimpleForm>
  </Edit>
)
