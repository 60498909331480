import ExcelIcon from '@mui/icons-material/DocumentScanner';
import PDFIcon from '@mui/icons-material/PictureAsPdf';
import { useEffect, useState } from 'react';
import {
  AutocompleteInput,
  Button,
  DeleteButton,
  Edit,
  ListButton,
  NumberInput,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  TopToolbar,
  required,
  useEditController,
} from 'react-admin';
import { SERVER_URL } from '../../config';
import { isModificationPermission } from '../../helpers/functions';
import restClient from '../../providers/restClient';
import ItemCostView from './ItemCostView';
import { useGlobal } from '../../context/global.js';

export const ItemEdit = (props) => {
  const { record } = useEditController(props);
  const { locationGlobal } = useGlobal();

  const [boxes, setBoxes] = useState({
    loading: true,
    data: [],
  });
  const [itemsType, setItemsType] = useState({
    loading: true,
    data: [],
  });
  const [inks, setInks] = useState({
    loading: true,
    data: [],
  });

  const fetchBoxes = () =>
    restClient.getList('box-list-only', {
      pagination: { page: 1, perPage: -1 },
      sort: { field: 'id', order: 'ASC' },
    });

  const fetchItemTypes = () =>
    restClient.getList('item-type-list-only', {
      pagination: { page: 1, perPage: -1 },
      sort: { field: 'id', order: 'ASC' },
    });

  const fetchInks = () =>
    restClient.getList('ink-list-only', {
      pagination: { page: 1, perPage: -1 },
      sort: { field: 'id', order: 'ASC' },
    });

  const exportExcel = (e) => {
    e.preventDefault();

    window.open(
      `${SERVER_URL}/item_excel_report_download/${record.id}/item_excel_report.xlsx?location=${locationGlobal}`,
      '_blank'
    );
  };

  const exportPDF = (e) => {
    e.preventDefault();
    let localLocation;
    if (locationGlobal == 'Mexico') {
      localLocation = 'MX';
    } else if (locationGlobal == 'USA') {
      localLocation = 'US';
    }
    window.open(
      `${SERVER_URL}/item_pdf_download/${record.id}/item.pdf?location=${locationGlobal}`,
      '_blank'
    );
  };

  const Actions = () => (
    <TopToolbar>
      <Button
        sx={{
          marginTop: '1rem',
          fontSize: '0.8rem',
        }}
        onClick={exportExcel}
      >
        <ExcelIcon />
        Export Excel
      </Button>

      <Button
        sx={{
          marginTop: '1rem',
          fontSize: '0.8rem',
        }}
        onClick={exportPDF}
      >
        <PDFIcon />
        Export PDF
      </Button>

      <ListButton />
    </TopToolbar>
  );

  const ToolbarForm = (props) => {
    if (!isModificationPermission()) return false;

    return (
      <Toolbar {...props}>
        <SaveButton />
        <DeleteButton mutationMode="pessimistic" />
      </Toolbar>
    );
  };

  useEffect(() => {
    fetchBoxes()
      .then(({ data }) => {
        const boxes = data.map((box) => ({ id: box.id, name: box.name }));
        setBoxes({ loading: false, data: boxes });
      })
      .catch((err) => {
        console.log('Error fetching boxes', err);
      });

    fetchItemTypes()
      .then(({ data }) => {
        const item_types = data.map((box) => ({
          id: box.type_number,
          name: box.description,
        }));
        setItemsType({ loading: false, data: item_types });
      })
      .catch((err) => {
        console.log('Error fetching item types', err);
      });

    fetchInks()
      .then(({ data }) => {
        const inks = data.map((ink) => ({ id: ink.id, name: ink.name }));
        setInks({ loading: false, data: inks });
      })
      .catch((err) => {
        console.log('Error fetching inks', err);
      });
  }, []);

  return (
    <Edit {...props} actions={<Actions />}>
      <SimpleForm toolbar={<ToolbarForm />}>
        <TextInput fullWidth source="id" disabled validate={required()} />
        <NumberInput fullWidth source="item_number" validate={required()} />
        <TextInput
          fullWidth
          multiline
          source="description"
          validate={required()}
        />
        <AutocompleteInput
          fullWidth
          isLoading={boxes.loading}
          source="box_id"
          choices={boxes.data}
          validate={required()}
        />
        <AutocompleteInput
          fullWidth
          isLoading={boxes.loading}
          source="secondary_box_id"
          choices={boxes.data}
        />

        <NumberInput fullWidth source="number_of_pcs_per_secondary_box" />

        <AutocompleteInput
          fullWidth
          source="item_type_id"
          choices={itemsType.data}
          isLoading={itemsType.loading}
          validate={required()}
        />
        <AutocompleteInput
          fullWidth
          source="ink_id"
          choices={inks.data}
          isLoading={inks.loading}
          helperText="If the ink is not selected, the ink_cost will be taken into account for the calculations"
          validate={!record?.ink_cost && required()}
        />
        {!record?.ink_id && (
          <NumberInput fullWidth source="ink_cost" label="Ink Cost($)" />
        )}
        <NumberInput
          fullWidth
          source="number_of_pcs_per_box"
          label="Number Of PCS/Box"
          validate={required()}
        />
        <ItemCostView type="price" />
        <ItemCostView type="inventory" />
      </SimpleForm>
    </Edit>
  );
};
