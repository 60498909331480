// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root > div > div:nth-child(1) {
    background-color: #CD4353 !important;
}

.menu{
  background-color: white;
  padding: 0 !important;
  margin: 0 !important;
  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.5);
  min-height: 100vh;
}

@media screen and (min-width: 600px){
  .menu{
    min-height: calc(100vh - 48px);
  }
}

.popover li{
  margin: 0;
  padding: 0;
}

.popover a{
  color: black;
  padding: 12px 24px;
}

.menu .link a{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 42px;
  color: black;
  font-size: 0.9rem;
}

.menu .link span{
  margin-left: 0px;
  padding: 0px 16px 0px 24px;
}

.card-wrapper{
  padding: 0rem;
}

@media screen and (min-width: 600px){
  .card-wrapper{
    padding: 1rem;
    margin: 2rem;
  }
}

.filter{
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr !important;
  width: 100% !important;
}

.dashboard-title{
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @media screen and (min-width: 769px){
    grid-template-columns: 1fr 1fr;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,uBAAuB;AACzB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;EACE,uBAAuB;EACvB,qBAAqB;EACrB,oBAAoB;EACpB,2CAA2C;EAC3C,iBAAiB;AACnB;;AAEA;EACE;IACE,8BAA8B;EAChC;AACF;;AAEA;EACE,SAAS;EACT,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE;IACE,aAAa;IACb,YAAY;EACd;AACF;;AAEA;EACE,wBAAwB;EACxB,6CAA6C;EAC7C,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,0BAA0B;EAC1B,SAAS;;EAET;IACE,8BAA8B;EAChC;AACF","sourcesContent":["body {\n  margin: 0;\n  padding: 0;\n  font-family: sans-serif;\n}\n\n#root > div > div:nth-child(1) {\n    background-color: #CD4353 !important;\n}\n\n.menu{\n  background-color: white;\n  padding: 0 !important;\n  margin: 0 !important;\n  box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.5);\n  min-height: 100vh;\n}\n\n@media screen and (min-width: 600px){\n  .menu{\n    min-height: calc(100vh - 48px);\n  }\n}\n\n.popover li{\n  margin: 0;\n  padding: 0;\n}\n\n.popover a{\n  color: black;\n  padding: 12px 24px;\n}\n\n.menu .link a{\n  width: 100%;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  height: 42px;\n  color: black;\n  font-size: 0.9rem;\n}\n\n.menu .link span{\n  margin-left: 0px;\n  padding: 0px 16px 0px 24px;\n}\n\n.card-wrapper{\n  padding: 0rem;\n}\n\n@media screen and (min-width: 600px){\n  .card-wrapper{\n    padding: 1rem;\n    margin: 2rem;\n  }\n}\n\n.filter{\n  display: grid !important;\n  grid-template-columns: 1fr 1fr 1fr !important;\n  width: 100% !important;\n}\n\n.dashboard-title{\n  display: grid;\n  grid-template-columns: 1fr;\n  gap: 1rem;\n\n  @media screen and (min-width: 769px){\n    grid-template-columns: 1fr 1fr;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
