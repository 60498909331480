import EditIcon from '@mui/icons-material/Edit'
import ShowIcon from '@mui/icons-material/Visibility'
import React from 'react'
import { CreateButton, Datagrid, EditButton, List, TextField, TopToolbar } from 'react-admin'
import { isModificationPermission } from '../../helpers/functions'

const Actions = ({
  filters,
  displayedFilters,
  filterValues,
  resource,
  showFilter,
}) => (
  <TopToolbar>
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}

    {isModificationPermission() && <CreateButton />}
  </TopToolbar>
)

export const ItemTypeListing = (props) => (
  <List
    actions={<Actions />}
    title='Item Types Listing'
    sort={{ field: 'id', order: 'ASC' }}
    {...props}
  >
    <Datagrid
      isRowSelectable={() => false}
    >
      <TextField source='id' />
      <TextField source='type_number' />
      <TextField source='description' />
      
      <EditButton
        label={isModificationPermission() ? 'Edit' : 'View'}
        icon={isModificationPermission() ? <EditIcon /> : <ShowIcon />}
      />
    </Datagrid>
  </List>
)
