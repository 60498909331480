import EditIcon from '@mui/icons-material/Edit'
import ShowIcon from '@mui/icons-material/Visibility'
import { Card } from '@mui/material'
import React from 'react'
import { ChipField, CreateButton, Datagrid, EditButton, FunctionField, List, TextField, TopToolbar } from 'react-admin'
import { isModificationPermission, roundNumber } from '../../helpers/functions'
import { JobFilter } from './JobFilter'

const Actions = ({
  filters,
  displayedFilters,
  filterValues,
  resource,
  showFilter,
}) => (
  <TopToolbar>
  {filters &&
    React.cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}
  
    {isModificationPermission() && <CreateButton />}
  </TopToolbar>
)

export const JobList = (props) => {
  return (
    <Card className='card-wrapper'>
      <List
        title='All Jobs'
        sort={{ field: 'id', order: 'ASC' }}
        filters={<JobFilter />}
        perPage={10}
        actions={<Actions />}
        {...props}
      >
        <Datagrid
          isRowSelectable={() => false}
        >
          <TextField source='job_number' />
          <TextField source='description' />
          
          <ChipField source='screen_size' />
          <FunctionField
            source='wages_per_hour'
            label='Wages/hr ($)'
            render={
              record => {
                return <span>${roundNumber(record.wages_per_hour)}</span>
            }}
          />
    
          <EditButton
            label={isModificationPermission() ? 'Edit' : 'View'}
            icon={isModificationPermission() ? <EditIcon /> : <ShowIcon />}
          />
        </Datagrid>
      </List>
    </Card>
  )
}
